import {store} from "../../../../store/index";
import Api from "../../../../store/Services/Api";
class LedgerServices{
    getLedgerElements(){
        const url = `api/account-report/ledger/elements`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getAccountLists(slug){
        const url = `api/account-report/ledger/accounts/${slug}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getLedger(param){
        const url = `api/account-report/ledger/filter`
        const params = {
            'account_id':param.account_id,
            'account_type':param.account_type,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
}
export default new LedgerServices();