<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Account Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Ledger
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Ledger</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <!-- <div class="report-tool-icon mr-1">
            <i class="fas fa-download" title="Download"></i>
          </div> -->
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <!-- <select>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select> -->
          <select class="mg-t-5 mr-2">
            <option>2021-22</option>
          </select>
           <!-- <v-select
              name="account_type"
              placeholder="Select Account Type"
              class="form-control"
              label="name"
              :options="options"
              :key="options.slug"
              @change="getAccounts"
              v-model="params.account_type"
            ></v-select> -->
          <select v-model="params.account_type" class="filter-input mg-t-5 mr-2" @change="getAccounts">
            <option value="">Select Account Type</option>
            <option
              v-for="(type, index) in accountTypes"
              :key="index"
              :value="type.slug"
            >
              {{ type.name }}
            </option>
          </select>
          <select v-model="params.account_id" class="filter-input mr-t-5 mr-2" @change="getLedger">
            <option value="">Select Account</option>
            <option
              v-for="(account, index) in accounts"
              :key="index"
              :value="account.id"
            >
              {{ account.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <table class="table table2 table-hover">
      <thead>
        <tr>
          <th class="wd-15p table-start-item">Date</th>
          <th class="wd-35p">Description</th>
          <th class="wd-15p text-right table-end-item">Dr. Amount</th>
          <th class="wd-15p text-right table-end-item">Cr. Amount</th>
          <th class="wd-20p text-right table-end-item">Balance</th>
        </tr>
      </thead>
      <tbody v-if="account_selected && !loading && ledgerLists.length > 0">
        <tr v-for="(ledger, index) in ledgerLists" :key="index">
          <th scope="row" class="table-start-item">
            {{ ledger.date }}
          </th>
          <td>
            {{ ledger.description }}
          </td>
          <td class="text-right table-end-item">{{ parseDigitForSlip(ledger.debit) }}</td>
          <td class="text-right table-end-item">{{ parseDigitForSlip(ledger.credit) }}</td>
          <td class="text-right table-end-item">
            {{ parseDigitForSlip(Math.abs(ledger.balance ))}} {{ ledger.sign }}.
          </td>
        </tr>
         <tr>
            <td></td>
            <td>Total</td>
            <td class="text-right table-end-item">{{parseDigitForSlip(ledgerTotals.debit_total)}}</td>
            <td class="text-right table-end-item">{{parseDigitForSlip(ledgerTotals.credit_total)}}</td>
            <td class="text-right table-end-item">{{parseDigitForSlip(Math.abs(ledgerTotals.total_balance))}} {{ledgerTotals.total_sign }}</td>
          </tr>
      </tbody>
      <tbody v-else-if="!account_selected">
        <tr>
          <td colspan="11" class="text-center">Please select Account Type and Account to display respective ledger...</td>
        </tr>
      </tbody>
      <tbody v-else-if="account_selected && !loading && ledgerLists.length == 0">
         <tr>
          <td colspan="11" class="text-center">No Records Found...</td>
        </tr>
      </tbody>
      <tbody v-else-if="loading">
        <tr>
          <td colspan="11" class="text-center">Loading...</td>
        </tr>
      </tbody>

    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./LedgerServices/Services";
export default {
  data() {
    return {
      loading:false,
      options:[],
      account_selected:false,
      params:{
        account_id:'',
        account_type:'',
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists3",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("ledgers",["ledgerLists","accountTypes","ledgerTotals","accounts"])
  },
  methods: {
    getData() {
      this.getElements();
    },
    print(){
      window.print();
    },
    getElements(){
      Services.getLedgerElements().then(res=>{
        this.$store.commit("ledgers/setAccountTypes",res.data.data);
        this.options = res.data.data;
        console.log(this.options);
      }).catch(err=>{
        console.log(err);
      });
    },
    getAccounts(){
      this.params.account_id ='';
      this.$store.commit("ledgers/setLedgerLists",[]);
      Services.getAccountLists(this.params.account_type).then(res=>{
        this.$store.commit("ledgers/setAccounts",res.data.data);
        
      }).catch(err=>{
        console.log(err);
      })
    },
    getLedger(){
      this.account_selected=true;
      this.loading = true;
      Services.getLedger(this.params).then(res=>{
        this.$store.commit("ledgers/setLedgerLists",res.data.data.data);
        this.$store.commit("ledgers/setLedgerTotals",res.data.data);
      }).catch(err=>{
        console.log(err);
      }).finally(()=>{
        this.loading = false;
        //console.log(this.ledgerLists);
      });
    }
  },
  beforeMount() {
    this.getData();
  },
};
</script>
<style>
</style>